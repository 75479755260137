<!--
 * @Descripttion: 中奖海报页面
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-04-12 09:45:45
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-06 16:29:14
-->

<template>
  <div class="poster-box" v-loading="isLoading">
    <div class="content">
      <div class="top-banner">
        <div class="top-title">
          <h2>{{ title }}</h2>
        </div>
        <el-button @click="shareVisible = true" :disabled="false">
          <img src="../../../../assets/img/prize/share-icon.svg" alt="share" />
        </el-button>
      </div>
      <div class="prize-container" >
        <div class="prize-content">
          <h3>恭喜以下中奖人员</h3>
          <div class="desc">
            <template v-for="{id, title,award_name, picture, lucky_users, isMore = false} in prizeList" :key="id">
              <div class="gainer-list">
                <p class="prize-title">{{ title }}</p>
                <p class="prize-name">{{ award_name }}</p>
                <div class="prize-img-box">
                  <img class="prize-img" :src="picture ? picture : defaultPrizeImg" alt="prizeimg" />
                </div>
                <div :class="['user-list', lucky_users.length > 10 && !isMore ? 'max-height' : '', lucky_users.length < 6 ? 'only-one-line' : '']">
                  <template v-for="{user,user_id} in lucky_users" :key="user_id">
                    <div class="user-info">
                      <UserAvatar :user="user" size="72" fontSize="16" medalSize="0"></UserAvatar>
                      <p class="user-name">{{user.name}}</p>
                    </div>
                  </template>
                </div>
                <p class="show-more-text" v-if="lucky_users.length > 10" @click="isGetMore(id)">
                  <span>{{ !isMore ? '展开更多' : '收起' }}</span>
                  <svg width="34" height="12" viewBox="0 0 34 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.306 7.007L0 12V9.347l7.407-3.185v-.054L0 2.664V0l10.306 5.253v1.754zM22.176 7.007L11.87 12V9.347l7.407-3.185v-.054L11.87 2.664V0l10.306 5.253v1.754zM34 7.007L23.694 12V9.347l7.407-3.185v-.054l-7.407-3.444V0L34 5.253v1.754z" fill="#FF6828"/></svg>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <p class="prize-owner-text">本次抽奖由{{owner}}发起</p>
    </div>
    <!-- 分享弹窗 -->
    <el-dialog
      v-model="shareVisible"
      custom-class="poster-share-dialog"
      :width="500"
      :show-close="false"
      @close="shareVisible = false">
      <template #title>
        <div class="title">
          <p>分享至动态</p>
          <span class="close-icon" @click="shareVisible = false"></span>
        </div>
      </template>
      <div class="share-content">
        <p>微博：</p>
        <CommonEditor
          buttonText=""
          editorType="share"
          limit="1200"
          placeholder="想说点什么呢~"
          ref="shareInputRef"
        ></CommonEditor>
      </div>
      <template #footer>
        <div class="button-box">
          <el-button class="back-button" size="small" @click="shareVisible = false">取消</el-button>
          <el-button class="sure-button" type="primary" size="small" :disabled="isDisable" @click="toSharePoster"
            >发布</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getAtUserID, getMessageByRes } from '@/utils/tools'
import { feedsPublishfeed } from '@/apis/blogs.js'
import { getLuckyUsers } from '@/apis/prize.js'
import { dialogMsg, myToast } from '@/utils/dialogMsg.js'
import mySocket from '@/mixin/socket.js'
import { getCookies } from '@/utils/auth.js'
import eventBus from '@/utils/eventBus.js'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import { FEEDFROM_TYPE } from '@/utils/const.js'

export default {
  components: { CommonEditor },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    getCookies('token') && store.dispatch('getUserList')
    const state = reactive({
      shareVisible: false,
      shareInputRef: null,
      ruleID: route.query.ruleID || 0,
      title: '',
      owner: '',
      prizeList: [],
      defaultPrizeImg: require('@/assets/img/prize/default-prizeimg.png'),
      isLoading: true,
      isDisable: false
    })
    onMounted(() => {
      initData()
    })
    const initData = () => {
      getLuckyUsers({ rule_id: state.ruleID })
        .then((res) => {
          state.isLoading = false
          if (res.code === 0) {
            state.title = res.data.title
            state.owner = res.data.user.name
            state.prizeList = res.data.prize_list
          } else {
            dialogMsg('error', res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const isGetMore = (id) => {
      const obj = state.prizeList.filter(item => item.id === id)[0]
      obj.isMore = !obj.isMore
    }

    watch(
      () => state.shareVisible,
      (newValue) => {
        if (!newValue) {
          state.shareInputRef.inputRef.clearContent()
        }
        eventBus.$emit('isDialogs', !newValue)
      }
    )

    watch(
      () => state.shareInputRef?.isDisable,
      (newValue) => {
        state.isDisable = !newValue
      }
    )

    // 分享提交
    const toSharePoster = (e) => {
      const html = state.shareInputRef.inputContent.html
      const atUserArr = getAtUserID(html)
      // 发布动态
      const datas = {
        feed_content: html,
        feed_from: FEEDFROM_TYPE.PC,
        repostable_type: 'prize_poster',
        repostable_id: state.ruleID,
        at_user: atUserArr,
        publish_status: 1
      }
      feedsPublishfeed(datas)
        .then((res) => {
          if (res.code === 0) {
            myToast({ type: 'success', message: '动态发布成功' })
            // 发动态
            const { sendToSocket } = mySocket()
            sendToSocket(undefined, { type: 1 })
            // @了其他人
            sendToSocket(datas.at_user, { type: 4 })
            state.shareVisible = false
            router.push({ path: '/blogs/dynamics' })
          } else {
            const message = getMessageByRes(res)
            myToast({ type: 'error', message })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return {
      ...toRefs(state),
      isGetMore,
      toSharePoster
    }
  }
}
</script>

<style lang="less" scoped>
.poster-box {
  max-width: 640px;
  width: 100%;
  background: linear-gradient(to bottom, #FFB627, #C668FF);
  margin: 0px auto;
  padding-bottom: 20px;
  :deep(.emoji-picker) {
    transform: scale(1.5) translateY(-5px);
  }
}
.top-banner {
  position: relative;
  width: 640px;
  height: 599px;
  background: url(~@/assets/img/prize/poster-banner.png);
  .top-title {
    position: absolute;
    bottom: 17px;
    left: 60px;
    width: 520px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FF6828;
    background-clip: text;
    padding: 10px 20px;
    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 34px;
      text-align: center;
      margin-bottom: 4px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-shadow: 1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 0 0 white, -1px 0 0 white, 0 1px 0 white, 0 -1px 0 white;
    }
    .owner {
      font-size: 20px;
      line-height: 1.2;
      font-weight: bold;
    }
  }
  .el-button {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    border-color: transparent;
    background: transparent;
    padding: 0;
    &:hover {
      opacity: 0.6;
    }
    &.is-disabled {
      opacity: 1 !important;
    }
  }
}
.prize-container {
  width: 564px;
  border-radius: 20px;
  background: #FFFFFF33;
  margin: 0 auto;
  padding: 8px;
  .prize-content {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #FFF1ED, #FFF3EA);
    border-radius: 20px;
    padding: 30px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #F95813;
  }
  .desc {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .show-more-text {
      margin-top: 26px;
      font-size: 18px;
      line-height: 24px;
      color: #FF6828;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .gainer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 27px 22px 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 12px;
    &:first-child {
      padding-top: 30px;
    }

    .prize-title {
      min-width: 156px;
      background: linear-gradient(to right, #FFC047, #FF7E86);
      border-radius: 100px;
      font-size: 24px;
      line-height: 30px;
      padding: 8px 15px;
      color: #fff;
      text-align: center;
    }
    .prize-name {
      margin: 30px 0 20px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      color: #F95813;
    }
    .prize-img-box{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 158px;
      height: 158px;
      background: url(~@/assets/img/prize/prize-img-bg.png);
    }
    .prize-img {
      width: 150px;
      height: 150px;
      border-radius: 20px;
    }
    .user-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 46px;
      &.max-height {
        max-height: 222px;
        overflow: hidden;
      }
      .user-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
      }
      :deep(.user-avatar),.user-name{
        margin:0 12px;
      }
      .user-name{
        font-size: 16px;
        line-height: 21px;
        margin-top: 6px;
        color: #333;
        max-width: 81px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    // 每行首位头像处理
    .user-list:not(.only-one-line) .user-info{
      &:nth-child(5n + 1){
        :deep(.user-avatar),.user-name{
          margin-left: 0;
        }
      }
      &:nth-child(5n){
        :deep(.user-avatar),.user-name{
          margin-right: 0;
        }
      }
    }
  }

  .max-height + .show-more-text {
    margin-top: 50px;
    svg {
      transform: rotate(0deg);
    }
  }
}

.prize-owner-text{
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

:deep(.poster-share-dialog) {
  border-radius: 6px;
  .el-dialog__header {
    padding: 24px 30px 0px 30px;
    .title {
      position: relative;
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: @default-text-color;
      }
      .close-icon {
        position: absolute;
        top: -8px;
        right: -12px;
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: url(../../../../assets/img/prize/close-icon.svg);
      }
    }
  }
  .el-dialog__body {
    padding: 20px 30px 0;
    .share-content {
      & > p {
        line-height: 21px;
        font-size: 16px;
        color: @default-text-color;
        margin-bottom: 10px;
      }
      .arena {
        padding: 12px 15px;
        min-height: 142px;
      }
    }
  }
  .el-dialog__footer {
    padding: 0 24px 16px 20px;
  }
}
:deep(.button-box) {
  .el-button {
    width: 85px;
    height: 32px;
    border-radius: 30px;
    line-height: 30px;
    padding: 0;
    border: none;
  }
  .back-button {
    color: #FF6828;
    background: rgba(255, 104, 40, 0.15);
  }
  .sure-button {
    color: #ffffff;
    background: #FF6828;
    margin-left: 8px;
    &.is-disabled {
      opacity: 0.6;
    }
  }
}
.long-string {
  font-size: 21px !important;
}
</style>
