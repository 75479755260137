import { getCookies } from '@/utils/auth'
import sha1 from 'js-sha1'
/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
export function createUniqueString () {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 数字存储大小格式化
 * @param {number} bytes 存储大小 单位：Byte
 * @return {string} 2MB
 */
export function getFileSize (bytes) {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
}

/**
   * 获取@列表中的id
   * 传入需要发布的值 state.inputContent.html
   */
export function getAtUserID (value) {
  const reg = /<a[^>]*href=[ '"]([^"]*)[' "][^>]*>(.*?)<\/a>/g
  value = value.replace(/(\n)+|(\r\n)+/g, '')
  const resReg = value.match(reg)
  if (!resReg) {
    return []
  }
  const arr = new Set()
  resReg.forEach((item) => {
    if (item.includes('data-isatcompanyuserid')) {
      !isNaN(Number(item.split('data-isatcompanyuserid="')[1].split('">')[0]))
        ? arr.add(Number(item.split('data-isatcompanyuserid="')[1].split('">')[0]))
        : arr.add(Number(item.split('"').filter(i => i.includes('personalCenter'))[0].split('id=')[1]))
    }
  })
  return Array.from(arr)
}

// 判断当前url是否携带参数
export function getQueryVariable (variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

/**
 * [isEqualIPAddress 判断两个IP地址是否在同一个网段]
 * @param  {[String]}  addr1 [地址一]
 * @param  {[String]}  addr2 [地址二]
 * @param  {[String]}  mask  [子网掩码]
 * @return {Boolean}         [true or false]
 */
export function isEqualIPAddress (addr1, addr2, mask) {
  if (!addr1 || !addr2 || !mask) {
    console.log('各参数不能为空')
    return false
  }
  var
    res1 = []
  var res2 = []
  addr1 = addr1.split('.')
  addr2 = addr2.split('.')
  mask = mask.split('.')
  for (var i = 0, ilen = addr1.length; i < ilen; i += 1) {
    res1.push(parseInt(addr1[i]) & parseInt(mask[i]))
    res2.push(parseInt(addr2[i]) & parseInt(mask[i]))
  }
  if (res1.join('.') === res2.join('.')) {
    console.log('在同一个网段')
    return true
  } else {
    console.log('不在同一个网段')
    return false
  }
}

export function getDate (date) {
  var weekArray = ['日', '一', '二', '三', '四', '五', '六']
  // 是否  今天、昨天、前天
  const now = new Date()
  const formatIndex = new Date(date).getDay()
  let text = ''
  const yesterday = new Date(now.setTime(now.getTime() - 24 * 60 * 60 * 1000))
  if (formatDate() === date) { // 今天
    text = '今天'
  } else if (formatDate(yesterday) === date) { // 昨天
    text = `昨天 周${weekArray[formatIndex]}`
  } else { // 其他格式
    text = `${date}  周${weekArray[formatIndex]}`
  }
  return text
}

export function formatDate (now = new Date()) {
  const year = now.getFullYear()
  const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
  const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
  return `${year}-${month}-${day}` // 今天的日期
}

/**
 * @
 */
export const getImageList = (list) => {
  const arr = []
  let url = list[0]
  const img = new Image()
  img.src = url
  return new Promise((resolve, reject) => {
    img.onload = () => {
      if (img.width > 2048) {
        url = url.includes('image/format,jpg') ? `${url}/resize,m_fill,l_500` : `${url}?x-oss-process=image/resize,m_fill,l_500`
        arr.push(url)
        resolve(arr)
      } else {
        url = url.includes('image/format,jpg') ? `${url}/crop,x_0,y_0,w_2048,h_1024,g_nw/quality,Q_10` : `${url}?x-oss-process=image/crop,x_0,y_0,w_2048,h_1024,g_nw/quality,Q_10`
        arr.push(url)
        resolve(arr)
      }
    }
  })
}

export const getImageUrlList = (list = []) => {
  if (list.length === 1) {
    getImageList(list).then(res => {
      return res
    })
  }
  return list
}

export const deepClone = (obj) => {
  var copy

  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj !== 'object') return obj

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = []
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepClone(obj[i])
    }
    return copy
  }

  // Handle Function
  if (obj instanceof Function) {
    copy = function () {
      return obj.apply(this, arguments)
    }
    return copy
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {}
    for (var attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = deepClone(obj[attr])
    }
    return copy
  }

  throw new Error("Unable to copy obj as type isn't supported " + obj.constructor.name)
}

/**
 *
 * @param {*} type 类型 year hours scends ...
 * @param {*} time 时间戳
 */
export const reBackTime = (time) => {
  const parseTime = new Date(time)
  const date = {
    year: parseTime.getFullYear(),
    month: parseTime.getMonth(),
    day: parseTime.getDate(),
    hours: parseTime.getHours(),
    minutes: parseTime.getMinutes(),
    seconds: parseTime.getSeconds(),
    MM: `00${parseTime.getMonth() + 1}`.slice(-2),
    DD: `00${parseTime.getDate()}`.slice(-2),
    hh: `00${parseTime.getHours()}`.slice(-2),
    mm: `00${parseTime.getMinutes()}`.slice(-2),
    ss: `00${parseTime.getSeconds()}`.slice(-2)
  }

  return date
}

export const getsign = () => {
  const token = getCookies('token')
  const key = process.env.VUE_APP_KEY
  const timestamp = +new Date()
  // sha1加密 签名
  const sign = sha1(timestamp + token + key).toUpperCase()
  return { token, sign, timestamp }
}

/**
 *
 * @param {DOM} element
 * @returns 是否在视窗内
 */
export function isInViewPort (element) {
  const viewWidth = window.innerWidth || document.documentElement.clientWidth
  const viewHeight =
      window.innerHeight || document.documentElement.clientHeight
  const { top, right, bottom, left } = element.getBoundingClientRect()
  return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight
}

export function getWindowScrollTop () {
  let scrollTop = 0
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop
  } else if (document.body) {
    scrollTop = document.body.scrollTop
  }
  return scrollTop
}

/**
 * 获取时间
 * @returns hh:mm:ss
 */
export function getPrizeTime () {
  const time = new Date()
  const hour = checkTime(time.getHours()) // 获取时
  const minite = checkTime(time.getMinutes()) // 获取分
  const second = checkTime(time.getSeconds()) // 获取秒
  function checkTime (i) {
    if (i < 10) return '0' + i
    return i
  }
  return `${hour}:${minite}:${second}`
}

/**
 * 图片预加载
 * @params arr图片路径数组
 */
export const preloadImgs = (imgs) => {
  const images = []
  imgs.forEach((i, index) => {
    images[index] = new Image()
    images[index].src = i
  })
}

/**
 * 图片是否预加载完毕
 * @param {*} imgs imgs图片数组
 * @param {*} cb 图片加载完成回调
 */
export const loadImages = (imgs, cb) => {
  const imgList = []
  let imgLoadNum = 0
  imgs.forEach((i, index) => {
    imgList[index] = new Image()
    imgList[index].onload = () => {
      imgLoadNum += 1
      if (imgLoadNum === imgs.length) {
        cb()
      }
    }
    imgList[index].src = i
  })
}

/**
 * 视频是否预加载完毕
 * @param {*} videos videos视频数组
 * @param {*} cb 视频加载完成回调
 */
export const loadVideos = (videos, cb) => {
  const videoList = []
  let videoLoadNum = 0
  videos.forEach((i, index) => {
    videoList[index] = document.createElement('video')
    videoList[index].src = i
    videoList[index].preload = true
    videoList[index].muted = true
    document.querySelector('body').appendChild(videoList[index])
    videoList[index].onloadedmetadata = () => {
      videoLoadNum += 1
      if (videoLoadNum === videos.length) {
        cb()
      }
      videoList[index].remove()
    }
  })
}

/**
 * 防抖函数
 * @param {Function} fn
 * @param {number} delay
 * @returns {() => void}
 */
export const debounce = (fn, delay, immediate = true) => {
  let timer
  return function (...args) {
    if (timer) return
    if (immediate) fn.apply(null, args)
    timer = setTimeout(
      () => {
        if (!immediate) fn.apply(null, args)
        clearTimeout(timer)
        timer = null
      },
      delay || 100
    )
  }
}

export const getMessageByRes = (res, msg = '动态发布失败') => {
  // 18 违禁词 30015 话题有空格 30006话题超长
  return [18, 30015, 30006].includes(res.code) ? res.msg : msg
}
