/*
 * @Descripttion: 弹窗相关
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-06 09:11:00
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-26 15:04:46
 */
import { Toast } from 'vant'
import { ElMessageBox } from 'element-plus'

export function dialogMsg (type = 'success', message, teleport) {
  myToast({ type, message, teleport })
}

export function myToast (obj = { type: '', message: '', forbidClick: false }) {
  const { type, message, forbidClick } = obj
  const icon = {
    success: 'passed',
    error: 'close',
    warning: 'warning-o'
  }
  Toast({
    message,
    icon: icon[type],
    iconSize: '60px',
    forbidClick
  })
}

export function confirm (obj = { message: '', title: '提示', confirmButtonText: '确认', cancelButtonText: '取消', closeOnClickModal: true, success: function () {}, cancel: function () {} }) {
  ElMessageBox.confirm(obj.message, obj.title, {
    confirmButtonText: obj.confirmButtonText || '确认',
    cancelButtonText: obj.cancelButtonText || '取消',
    customClass: 'myMessageBox',
    center: true,
    roundButton: true,
    closeOnClickModal: obj.closeOnClickModal
  }).then(() => {
    obj.success()
  }).catch(() => {
    obj.cancel()
  })
}
