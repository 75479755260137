<template>
  <div class="option-draggable-list">
    <Vuedraggable
        v-model="optionsList"
        @end="onDragEnd"
        :item-key="createUniqueString()"
      >
        <template #item="{ element, index }">
          <div :class="['option-list', optionsList.length > 1 ? 'length1': '']">
              <template v-if="optionsList.length > 1" @click="delOptionList" >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="5" r="1" fill="#CCC"/><circle cx="13" cy="10" r="1" fill="#CCC"/><circle cx="13" cy="15" r="1" fill="#CCC"/><circle cx="7" cy="5" r="1" fill="#CCC"/><circle cx="7" cy="10" r="1" fill="#CCC"/><circle cx="7" cy="15" r="1" fill="#CCC"/></svg>
              </template>
              <el-input v-model="element.name"
                  placeholder="请输入选项名称"
                  maxlength="50"
                  :class="element.error ? 'is_error' : ''"></el-input>
              <el-select v-model="element.type" placeholder="请选择">
                <el-option value="1" label="单行文本"></el-option>
                <el-option value="2" label="多行文本"></el-option>
                <el-option value="3" label="图片"></el-option>
                <el-option value="4" label="视频"></el-option>
              </el-select>
              <el-switch v-model="element.required" />
              <div :class="index === 0 ? 'visible-bidden' : ''">
                <DeleteIcon
                  deleteText="这条投票设置"
                  :isTextShow="false"
                  @handleClick="delOptionList(index)">
                </DeleteIcon>
              </div>
          </div>
        </template>
    </Vuedraggable>
    <p class="add-icon" @click="addOptionList">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="9" width="14" height="2" rx="1" fill="#333"/><rect x="11" y="3.002" width="14" height="2" rx="1" transform="rotate(90.068 11 3.002)" fill="#333"/></svg>
      <span class="cursor-pointer">添加选项</span>
    </p>
    <!-- <el-button @click="optionSettingVisible = false" type="primary" size="small">取消</el-button>
    <el-button type="primary" size="small" @click="setOptionSetting">确定</el-button> -->
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose, watchEffect, defineEmits } from 'vue'
import Vuedraggable from 'vuedraggable'
import DeleteIcon from '@/components/icon/DeleteIcon.vue'
import { createUniqueString } from '@/utils/tools'

const defaultOption = [{ name: '', type: '', required: true }]

const props = defineProps({
  data: {
    type: Array,
    default: () => [{ name: '', type: '', required: true }]
  }
})

const optionsList = ref()

const onDragEnd = (e) => {
  //   const oldIndex = e.oldIndex // 旧索引
  //   const newIndex = e.newIndex // 新索引
  //   const [oldElement] = optionsList.value.splice(oldIndex, 1) // 删除 oldIndex 的元素
  //   optionsList.value.splice(newIndex, 0, oldElement)
}

const emit = defineEmits(['scrollToBottom'])
const addOptionList = () => {
  optionsList.value.push({ name: '', type: '', required: true })
  // 滚动条到最后
  emit('scrollToBottom')
}

const delOptionList = (index) => {
  optionsList.value.splice(index, 1)
}

watchEffect(() => {
  if (props.data.length > 0) {
    optionsList.value = props.data
  } else {
    optionsList.value = defaultOption
  }
})

defineExpose({
  optionsList
})
</script>

<style lang="less" scoped>
 .option-draggable-list{
  .option-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    svg {
      cursor: pointer;
    }
    .el-input{
      width: 225px;
      .el-input__inner{
        padding-right: 15px;
      }
      &.is_error{
        border: 1px solid #f56c6c;
      }
    }
    .el-select{
      width: 140px;
    }
    &.length1{
      margin-left: -24px;
    }
  }

  .add-icon{
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    width: fit-content;
    svg{
      cursor: pointer;
    }
  }

  .visible-bidden{
    visibility: hidden;
  }
 }
</style>
