<!--
 * @Descripttion: 日志左侧目录
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 10:00:38
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-25 11:51:15
-->
<template>
  <template v-if="list.length > 0">
    <el-scrollbar ref="scrollbarRef">
      <div v-for="item in list" :key="item.id" class="journal-item" :ref="catalogueRef">
        <p>{{ getDate(item.date)}}</p>
        <ul class="journal-ul">
          <li v-for="data in item.data" :key="data.id" @click="clickournal(data)" :class="{ 'active': data.index === activeIndex }">
            <div class="left">
              <UserAvatar
                :user="{avatar: data.avatar, name: data.name, id: data.user_id}"
                size="24"
                fontSize="11"
                medalSize="38"
              ></UserAvatar>
              <span class="title">
                <template v-if="data.template_name !== '空白模板'">
                  {{`${data.name}的${data.template_name}`}}
                </template>
                <template v-else>
                  {{data.fields[0].field_name}}
                </template>
              </span>
            </div>
            <el-tag size="small" :type="data.read_time ? 'success' : 'info'">{{data.read_time ? '已读' : '未读'}}</el-tag>
          </li>
        </ul>
      </div>
      <p class="more" v-if="showLoadMore" @click="loadMore">查看更多</p>
    </el-scrollbar>
  </template>
  <template v-else>
    <empty-data
      image="no-journal"
      description="无历史日志" size="80"></empty-data>
  </template>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { setJournalRead } from '@/apis/journal.js'
import eventBus from '@/utils/eventBus.js'
import { getDate } from '@/utils/tools'
import { useStore } from 'vuex'

export default {
  props: {
    journalList: { // 目录列表
      type: Array,
      default: () => []
    },
    // total: { // 总数量
    //   type: Number,
    //   default: 0
    // },
    showLoadMore: {
      type: Boolean,
      default: true
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  setup (props, { emit }) {
    // 左侧点击，滚动右侧
    eventBus.$on('readJournal', (data) => {
      readJournal(data)
    })
    const store = useStore()
    const state = reactive({
      list: computed(() => { return initList(props.journalList) }), // 日志列表
      teamLogList: computed(() => store.state.work.logList),
      // count: computed(() => props.total),
      showLoadMore: computed(() => props.showLoadMore),
      activeIndex: computed(() => props.activeIndex),
      journal: {}, // 当前阅读日志
      refList: [],
      scrollbarRef: null,
      currentUser: computed(() => store.state.userInfo.userInfos),
      readedSet: new Set()// 已读列表，避免重复发请求
    })

    watch(state.journal, () => {
      state.readedSet.add(state.journal.id)
    })

    const catalogueRef = (el) => {
      state.refList.push(el)
    }

    // 初始化数据
    const initList = (arr) => {
      if (arr.length === 0) return []
      // 根据日期分组
      const newArray = []
      arr.forEach((journal, index) => {
        journal.date = journal.created_at.split(' ')[0]
        journal.index = index
        let num = -1
        const isExists = newArray.some((newItem, j) => {
          if (journal.date === newItem.date) {
            num = j
            return true
          }
        })
        if (!isExists) {
          newArray.push({
            date: journal.date,
            data: [journal]
          })
        } else {
          newArray[num].data.push(journal)
        }
      })
      return newArray
    }
    // 点击日志
    const clickournal = (data) => {
      console.log('点击左侧', data.index)
      // 右侧内容对应滚动
      eventBus.$emit('scollerRightJournal', data.index)
    }

    // 阅读日志
    const readJournal = (journal) => {
      state.journal = journal
      // 其他的
      if (journal?.id && !journal.read_time) {
        const obj = {
          id: journal.id,
          type: 4
        }
        setJournalRead(obj).then(res => {
          if (res.code === 0) {
            journal.read_time = res.data.read_time
            state.readedSet.has(journal.id)
            if (state.journal.user_id !== state.currentUser.id) { // 不是自己阅读自己的日志
              eventBus.$emit('addReadCount', journal.id)
              // vuex未读日志-1
              store.commit('work/setReceiveNewJournal', -1)
            }
          }
        })
      }
    }

    const loadMore = () => {
      emit('loadMore')
    }
    return {
      ...toRefs(state),
      catalogueRef,
      getDate,
      loadMore,
      clickournal,
      readJournal
    }
  }
}
</script>

<style lang="less" scoped>
  .journal-item{
    p {
      color: #aaa;
      font-size: 12px;
      span{
        margin-right: 20px;
      }
    }
  }
  .journal-ul{
    margin: 8px 0;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      cursor: pointer;
      &:hover,&.active{
        background: #f2f1fe;
        border-radius: 6px;
      }
    }
    .left{
      display:flex;
      align-items: center;
      .user-avatar{
        margin-right: 8px;
      }
    }
    .title{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 97px;
      display: inline-block;
      font-size: 13px;
      line-height: 17px;
      color: @default-text-color;
    }
  }
  .el-tag{
    min-width: 32px;
    height: 17px;
    text-align: center;
    padding: 1px 2px;
    font-size: 10px;
    line-height: 13px;
    border-radius: 44px;
    --el-tag-background-color: #fff;
    &.el-tag--info{
      color: @active-text-color;
      --el-tag-font-color: @active-text-color;
      --el-tag-hover-color: @active-text-color;
      border-color: @active-text-color;
    }
    &.el-tag--success{
      color: @second-text-color;
      --el-tag-font-color: @second-text-color;
      --el-tag-hover-color: @second-text-color;
      border-color: @second-text-color;
    }
  }
  .more{
    text-align: center;
    font-size: 13px;
    color: @active-text-color;
    cursor: pointer;
    margin-top: 10px;
  }
</style>
